import { graphql } from 'gatsby';
import React from 'react';
import ImageContent from '../components/ImageContent';
import SinglePageBanner from '../components/SinglePageBanner';
import TextContent from '../components/TextContent';

const BlogPost = (props) => {
  const modules = props.modules.postContent;
  const post = props.post;
  console.log(modules);

  const components = {
    SinglePageBanner,
    TextContent,
    ImageContent,
  };

  return (
    <>
      {modules
        ? modules
            .filter(
              (module) => !module?.hideSection || module?.hideSection === 'no'
            )
            .filter(
              (module) =>
                module.__typename !==
                  'WpPost_Blogpost_PostContent_TextContent' &&
                module.__typename !== 'WpPost_Blogpost_PostContent_ImageContent'
            )
            .map((module, i) => {
              const moduleName = module.__typename.replace(
                'WpPost_Blogpost_PostContent_',
                ''
              );
              return (
                components[moduleName] &&
                React.createElement(components[moduleName], {
                  key: i,
                  module,
                  i,
                })
              );
            })
        : null}

      <div className="container blog-single-container m-auto lg:py-100 py-50 text-content px-20">
        {modules
          ? modules
              .filter(
                (module) =>
                  module.__typename ===
                    'WpPost_Blogpost_PostContent_TextContent' ||
                  module.__typename ===
                    'WpPost_Blogpost_PostContent_ImageContent'
              )
              .map((module, i) => {
                const moduleName = module.__typename.replace(
                  'WpPost_Blogpost_PostContent_',
                  ''
                );
                return (
                  components[moduleName] &&
                  React.createElement(components[moduleName], {
                    key: i,
                    module,
                    i,
                  })
                );
              })
          : null}

        <div>
          <div className="flex flex-row items-center gap-5 pt-30">
            <h6>Share this blog post:</h6>
            <ul className="flex flex-row items-center gap-5 sicon">
              <li>
                <a href="#">
                  <img
                    src="/images/icon-direct-message.png"
                    alt="icon-direct-message"
                  />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="/images/icon-mail.png" alt="icon-mail" />
                </a>
              </li>
              <li>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.GATSBY_URL}${post.uri}`}
                  target="_blank"
                >
                  <img src="/images/icon-facebook.png" alt="icon-facebook" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPost;

export const BlogPostFragment = graphql`
  fragment BlogPostFragment on WpPost {
    blogpost {
      postContent {
        __typename
        ...BlogSingleSingleBannerFragment
        ...TextContentFragment
        ...ImageContentFragment
      }
    }
  }
`;
