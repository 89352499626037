import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import BlogPost from "../modules/Blog";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Seo from "../components/Seo";

const BlogTemplate = (props) => {
  const post = props.data.post;

  useEffect(() => {
    document.body.classList.remove("nav-menu-open");
    gsap.utils.toArray(".fade-ani").forEach(function (box) {
      ScrollTrigger.create({
        trigger: box,
        toggleActions: "play none none none",
        once: true,
        onEnter: function () {
          gsap.fromTo(
            box,
            { autoAlpha: 0, y: 40 },
            { duration: 0.8, delay: 0.4, autoAlpha: 1, y: 0 }
          );
        },
      });
    });

    gsap.utils.toArray(".fade-img").forEach(function (box) {
      ScrollTrigger.create({
        trigger: box,
        toggleActions: "play none none none",
        once: true,
        onEnter: function () {
          gsap.fromTo(
            box,
            { autoAlpha: 0, y: 40 },
            { duration: 0.8, delay: 0.3, autoAlpha: 1, y: 0 }
          );
        },
      });
    });
  }, []);

  return (
    <Layout>
      <Seo seo={post.seo} />
      <BlogPost
        modules={post.blogpost}
        post={{ id: post.id, title: post.title, uri: post.uri }}
      />
    </Layout>
  );
};

export default BlogTemplate;
export const blogPostQuery = graphql`
  query blogById($id: String) {
    post: wpPost(id: { eq: $id }) {
      id
      title
      uri
      ...BlogPostFragment
      seo {
        canonical
        fullHead
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
      }
    }
  }
`;
