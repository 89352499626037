import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ImageContent = ({ module }) => {
  if (!module?.image || !module?.image?.gatsbyImage) {
    return null;
  }

  return (
    <div className="content fade-img">
      <GatsbyImage 
        image={getImage(module?.image)}
        alt={module?.image?.altText}
        className={module?.extraClasses}
      />
    </div>
  )
}

export default ImageContent;
export const ImageContentFragment = graphql`
  fragment ImageContentFragment on WpPost_Blogpost_PostContent_ImageContent {
    extraClasses
    image {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, quality: 90, width: 2000)
    }
  }
`;