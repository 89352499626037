import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";

const TextContent = ({ module }) => {
  if (!module?.text) {
    return null;
  }
  return (
    <div className="content fade-ani lg:py-30 py-15">
      {parse(module.text)}
    </div>
  )
}

export default TextContent;
export const TextContentFragment = graphql`
  fragment TextContentFragment on WpPost_Blogpost_PostContent_TextContent {
    extraClasses
    text
  }
`;